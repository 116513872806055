@font-face {
  font-family: 'Lausanne-300';
  
  
    src: url('Lausanne-300.eot');
    src: url('Lausanne-300.eot') format('embedded-opentype'),
         url('Lausanne-300.woff2') format('woff2'),
         url('Lausanne-300.woff') format('woff'),
         url('Lausanne-300.ttf') format('truetype'),
         url('Lausanne-300.svg#Lausanne-300') format('svg');
  font-weight: normal;
  font-style: normal;
}

body{
 font-family: 'Lausanne-300';
 
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;

}